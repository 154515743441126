import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Modal,
    FormHelperText,
    Divider,
} from '@mui/material';
import { toast } from 'react-toastify';
import { changeKundenPassword } from '../../services/clients/KundenApi';
import { useDispatch, useSelector } from 'react-redux';
import { getKundenChangePasswordModal, getKundenUpdateStatus, setKundenChangePasswordModal } from '../../services/clients/KundenSlice';

const AdminChangePassword = ( { id } ) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [err, setErr] = useState('');
    const dispatch = useDispatch();
    const status = useSelector(getKundenUpdateStatus);
    const open = useSelector(getKundenChangePasswordModal)

    const handleSubmit = (e) => {
        e.preventDefault();
        if(newPassword === '' || confirmPassword === '') {
            setErr('Please fill in all fields');
            return;
        }
        if(newPassword !== confirmPassword) {
            setErr('Passwords do not match');
            return;
        }
        else {
            setErr('');
            const data = {
                id: id,
                password: newPassword,
            };
            dispatch(changeKundenPassword(data));
        }

    };

    useEffect(() => {
        if(status === 'success') {
            toast.success('Password changed successfully');
            dispatch(setKundenChangePasswordModal(false))
        }
        if(status === 'rejected') {
            toast.error('An error occurred while changing the password');
        }
    }, [status]);

    const handleClose = () => {
        dispatch(setKundenChangePasswordModal(false))
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="change-password-modal"
            aria-describedby="change-password-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Container maxWidth="sm" sx={{bgcolor: 'white', pt: 1}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography fontFamily={'Roboto'} variant="h4" component="h1" gutterBottom>
                        Change Password
                    </Typography>
                    <Divider sx={{width: '100%', mb: 1}} />
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            width: '100%',
                            marginTop: 1,
                        }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <FormHelperText error>{err}</FormHelperText>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Change Password
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Modal>
    );
};

export default AdminChangePassword;
