import { createSlice } from '@reduxjs/toolkit';
import { createServiceQuote, deleteServiceQuote, getAllServiceQuotes, getServiceQuoteById, updateServiceQuote } from './ServiceQuoteApi';

const initialState = {
    data: [
        {
            id: 1,
            client: 'John Doe',
            issuedDate: '2022-01-01',
            serviceType: 'Service Type 1',
            answers: [
                {
                    question: 'Question 1',
                    answer: 'Answer 1',
                },
                {
                    question: 'Question 2',
                    answer: 'Answer 2',
                },
            ]
        }
    ],
    status: 'idle',
    error: false,
    msg: '',
    modalState: false,
    modalData: {},
};

const ServiceQuoteSlice = createSlice({
    name: 'ServiceQuoteSlice',
    initialState,
    reducers: {
        resetServiceQuoteStatus: (state) => {
            state.status = 'idle';
        },
        resetServiceQuoteMsg: (state) => {
            state.msg = '';
        },
        resetServiceQuoteError: (state) => {
            state.error = false;
        },
        resetServiceQuoteStatusAndMsg: (state) => {
            state.status = 'idle';
            state.msg = '';
        },
        setServiceQuoteModalState: (state, action) => {
            state.modalState = action.payload;
        },
        setServiceQuoteModalData: (state, action) => {
            state.modalData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createServiceQuote.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(createServiceQuote.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.data = action.payload;
        })
        .addCase(createServiceQuote.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = true;
            state.msg = action.payload;
        })

        //get service quote by id
        .addCase(getServiceQuoteById.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(getServiceQuoteById.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.data = action.payload;
        })
        .addCase(getServiceQuoteById.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = true;
            state.msg = action.payload;
        })

        //get all service quotes
        .addCase(getAllServiceQuotes.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(getAllServiceQuotes.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.data = action.payload;
        })
        .addCase(getAllServiceQuotes.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = true;
            state.msg = action.payload;
        })

        //update service quote
        .addCase(updateServiceQuote.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(updateServiceQuote.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.data = action.payload;
        })
        .addCase(updateServiceQuote.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = true;
            state.msg = action.payload;
        })

        //delete service quote
        .addCase(deleteServiceQuote.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(deleteServiceQuote.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.data = state.data.filter((serviceQuote) => serviceQuote.id !== action.payload);
        })
        .addCase(deleteServiceQuote.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = true;
            state.msg = action.payload;
        })
    }
});

export const { 

    resetServiceQuoteStatus, 
    resetServiceQuoteMsg, 
    resetServiceQuoteError, 
    resetServiceQuoteStatusAndMsg,
    setServiceQuoteModalState,
    setServiceQuoteModalData,

 } = ServiceQuoteSlice.actions;

export const getServiceQuoteData = (state) => state.ServiceQuoteSlice.data;
export const getServiceQuoteStatus = (state) => state.ServiceQuoteSlice.status;
export const getServiceQuoteError = (state) => state.ServiceQuoteSlice.error;
export const getServiceQuoteMsg = (state) => state.ServiceQuoteSlice.msg;
export const getServiceQuoteModalState = (state) => state.ServiceQuoteSlice.modalState;
export const getServiceQuoteModalData = (state) => state.ServiceQuoteSlice.modalData;

export default ServiceQuoteSlice.reducer;