import React, { Suspense, useEffect } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { handleOnlineState } from './services/internetConnection/InternetConnectionSlice';
import { AuthProvider } from './components/authContext/AuthProvider';

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    function handleOnline() {
      dispatch(handleOnlineState(true));
    }

    function handleOffline() {
      dispatch(handleOnlineState(false));
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });
  
  return (
    <Suspense>
      <ToastContainer />
      <Router>
      <AuthProvider>
      <Routes>
        <Route path='/*' element={<MainPage />} />
      </Routes>
      </AuthProvider>
        </Router>
    </Suspense>
  )
}

export default App