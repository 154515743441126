import { createSlice } from '@reduxjs/toolkit';
import { changeKundenPassword, createKunden, deleteKunden, getAllKunden, getKundenById, updateKunden } from './KundenApi';

const initialState = {
    data: [],
    status: 'idle',
    msg: '',
    filteredData: [],
    updateStatus: 'idle',
    updateMsg: '',
    changePasswordModal: false,
};

const KundenSlice = createSlice({
    name: 'KundenSlice',
    initialState,
    reducers: {
        resetKudenMsg: (state) => {
            state.msg = '';
        },
        filterKunden: (state, action) => {
            state.filteredData = state.data.filter((kunde) => {
                return kunde.name.toLowerCase().includes(action.payload.toLowerCase());
            });
        },
        resetKundenStatus: (state) => {
            state.status = 'idle';
        },
        resetKudenStatusAndMsg: (state) => {
            state.status = 'idle';
            state.msg = '';
        },
        resetUpdateKundenStatus: (state) => {
            state.updateStatus = 'idle';
        },
        resetUpdateKundenStatusAndMsg: (state) => {
            state.updateStatus = 'idle';
            state.updateMsg = '';
        },
        setKundenChangePasswordModal: (state, action) => {
            state.changePasswordModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllKunden.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(getAllKunden.fulfilled, (state, action) => {
            state.status = 'success';
            state.data = action.payload;
            state.filteredData = action.payload;
        })
        .addCase(getAllKunden.rejected, (state, action) => {
            state.status = 'rejected';
            state.msg = action.payload;
        })
        .addCase(getKundenById.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(getKundenById.fulfilled, (state, action) => {
            state.status = 'success';
            state.data = action.payload;
        })
        .addCase(getKundenById.rejected, (state, action) => {
            state.status = 'rejected';
            state.msg = action.payload;
        })
        .addCase(createKunden.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(createKunden.fulfilled, (state, action) => {
            state.status = 'success';
            state.msg = action.payload;
        })
        .addCase(createKunden.rejected, (state, action) => {
            state.status = 'rejected';
            state.msg = action.payload;
        })
        .addCase(updateKunden.pending, (state) => {
            state.updateStatus = 'pending';
        })
        .addCase(updateKunden.fulfilled, (state, action) => {
            state.updateStatus = 'success';
        })
        .addCase(updateKunden.rejected, (state, action) => {
            state.updateStatus = 'rejected';
            state.updateMsg = action.payload;
        })
        .addCase(changeKundenPassword.pending, (state) => {
            state.updateStatus = 'pending';
        })
        .addCase(changeKundenPassword.fulfilled, (state, action) => {
            state.updateStatus = 'success';
        })
        .addCase(changeKundenPassword.rejected, (state, action) => {
            state.updateStatus = 'rejected';
            state.updateMsg = action.payload;
        })
        .addCase(deleteKunden.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(deleteKunden.fulfilled, (state, action) => {
            state.status = 'success';
            state.msg = action.payload;
        })
        .addCase(deleteKunden.rejected, (state, action) => {
            state.status = 'rejected';
            state.msg = action.payload;
        });
    }
});

export const { 
    resetKudenMsg, 
    filterKunden, 
    resetKundenStatus, 
    resetKudenStatusAndMsg, 
    resetUpdateKundenStatus, 
    resetUpdateKundenStatusAndMsg,
    setKundenChangePasswordModal,
 } = KundenSlice;

export const getKundenData = (state) => state.KundenSlice.data;
export const getKundenStatus = (state) => state.KundenSlice.status;
export const getKundenUpdateStatus = (state) => state.KundenSlice.updateStatus;
export const getKundenMsg = (state) => state.KundenSlice.msg;
export const getKundenUpdateMsg = (state) => state.KundenSlice.updateMsg;
export const getFilteredKundenData = (state) => state.KundenSlice.filteredData;
export const getKundenChangePasswordModal = (state) => state.KundenSlice.changePasswordModal;

export default KundenSlice.reducer;