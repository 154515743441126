import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Typography, Button } from '@mui/material';
import { getServiceQuoteModalData, getServiceQuoteModalState, setServiceQuoteModalState } from '../../services/serviceQuote/ServiceQuoteSlice';

const ServiceQuoteModal = () => {
    const quote = useSelector(getServiceQuoteModalData); // Assuming 'quote' is the slice name in the Redux store
    const open = useSelector(getServiceQuoteModalState); // Assuming 'status' is the slice name in the Redux store
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setServiceQuoteModalState(false));
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 2,
                }}
            >
                <Typography variant="h6" component="h2">
                    Service Quote
                </Typography>
                <Typography variant="body1" component="p">
                    {/* Display quote data here */}
                    {quote.id}
                </Typography>
                <Button variant="contained" onClick={handleClose}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default ServiceQuoteModal;