import { configureStore } from "@reduxjs/toolkit";
import InternetConnectionSlice from "../services/internetConnection/InternetConnectionSlice";
import KundenSlice from "../services/clients/KundenSlice";
import ServiceQuoteSlice from "../services/serviceQuote/ServiceQuoteSlice";


export const store = configureStore({
  reducer: {
    InternetConnectionSlice: InternetConnectionSlice,
    KundenSlice: KundenSlice,
    ServiceQuoteSlice: ServiceQuoteSlice,
  },
});
