import React, { useEffect } from 'react';
import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceQuoteData, getServiceQuoteStatus, resetServiceQuoteStatusAndMsg, setServiceQuoteModalState } from '../../services/serviceQuote/ServiceQuoteSlice';
import { getAllServiceQuotes } from '../../services/serviceQuote/ServiceQuoteApi';
import { toast } from 'react-toastify';

const ServiceQuoteTable = () => {
    const dispatch = useDispatch();
    const data = useSelector(getServiceQuoteData);
    const status = useSelector(getServiceQuoteStatus);
    const columnNames = ['Nr', 'Client', 'Issued Date', 'Service Type', 'Answers', 'Actions'];

    useEffect(() => {
        switch (status) {
          case "pending":
            toast.info("Fetching service quotes...", {
              onClose: () => {
                dispatch(resetServiceQuoteStatusAndMsg());
              },
            });
            break;
          case "fulfilled":
            break;
          case "rejected":
            toast.error("Failed to fetch service quotes", {
              onClose: () => {
                dispatch(resetServiceQuoteStatusAndMsg());
              },
            });
            break;
          default:
            break;
        }
    }, [status]);

    useEffect(() => {
        dispatch(getAllServiceQuotes());
    }, [dispatch]);

    return (
        <TableContainer sx={{maxHeight: 'calc(100vh - 70px)', overflow: 'auto'}}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        {columnNames.map((columnName) => (
                            <TableCell sx={{bgcolor: 'white', p: '10px 10px'}} key={columnName}>{columnName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Render table rows here */}
                    {data?.map((item, index) => (
                        <TableRow onDoubleClick={() => {
                            dispatch(setServiceQuoteModalState(true));
                        }} key={index}>
                            {/* Render table cells for each column */}
                            <TableCell sx={{bgcolor: 'white', p: '10px 10px'}}>{index + 1}</TableCell>
                            <TableCell sx={{bgcolor: 'white', p: '10px 10px'}}>{/* Generate random client name */ item.client}</TableCell>
                            <TableCell sx={{bgcolor: 'white', p: '10px 10px'}}>{/* Generate random issued date */ item.issuedAt}</TableCell>
                            <TableCell sx={{bgcolor: 'white', p: '10px 10px'}}>{/* Generate random service type */ item.serviceName}</TableCell>
                            <TableCell sx={{bgcolor: 'white', p: '0'}}>
                              
                            </TableCell>
                            <TableCell sx={{bgcolor: 'white', p: '10px 10px'}}>{/* Render actions buttons */}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ServiceQuoteTable;