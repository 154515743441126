import React from 'react'
import KundenTable from '../components/kunden/KundenTable'
import AdminChangePassword from '../components/kunden/AdminChangePassword'

const Kunden = () => {
  return (
    <div>
        <KundenTable />
        <AdminChangePassword />
    </div>
  )
}

export default Kunden