import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../utils/consts';

// Create the authentication context
export const AuthContext = createContext();

// Create the authentication provider component
export const AuthProvider = ({ children }) => {
    // State to hold the authentication status
    const [session, setSession] = useState(null);
    const navigate = useNavigate();

    // Function to toggle the authentication status
    const checkAuth = async () => {
        try {
            const axiosRes = await axios.get(`${BASE_URL}/auth/check-auth`, {
                withCredentials: true,
            });
            const response = axiosRes.data;
            setSession(response);
        } catch (error) {
            toast.error('An error occurred while checking the authentication status');
        }
    }

    useEffect(() => {
        checkAuth();
    }, []);

    // Provide the authentication status and toggle function to the children components
    return (
        <AuthContext.Provider value={{ session, setSession }}>
            {children}
        </AuthContext.Provider>
    );
};