import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../utils/consts';

// Define the base URL for your API
const url = `${BASE_URL}/api/v1/client`;

// Create the async thunk for fetching Kunden
export const getAllKunden = createAsyncThunk('kunden/fetch', async ( _, { rejectWithValue } ) => {
    try {
        const response = await axios.get(`${url}/getall`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Create the async thunk for fetching a Kunden by ID
export const getKundenById = createAsyncThunk('kunden/fetchById', async ( kundeId, { rejectWithValue } ) => {
    try {
        const response = await axios.get(`${url}/get/${kundeId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Create the async thunk for creating a Kunden
export const createKunden = createAsyncThunk('kunden/create', async ( kundeData, { rejectWithValue } ) => {
    try {
        const response = await axios.post(`${url}/create`, kundeData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Create the async thunk for updating a Kunden
export const updateKunden = createAsyncThunk('kunden/update', async ( kundeData, { rejectWithValue } ) => {
    try {
        const response = await axios.put(`${url}/update/${kundeData.id}`, kundeData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const changeKundenPassword = createAsyncThunk('kunden/updatePassword', async ( kundeData, { rejectWithValue } ) => {
    try {
        const response = await axios.put(`${url}/change/password`, kundeData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Create the async thunk for deleting a Kunden
export const deleteKunden = createAsyncThunk('kunden/delete', async ( kundeId, { rejectWithValue } ) => {
    try {
        await axios.delete(`${url}/delete/${kundeId}`);
        return kundeId;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});