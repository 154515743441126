import React from 'react'
import ServiceQuoteTable from '../components/serviceQuote/ServiceQuoteTable'
import ServiceQuoteModal from '../components/serviceQuote/ServiceQuoteModal'

const ServiceQuotePage = () => {
  return (
    <div>
        <ServiceQuoteTable />
        <ServiceQuoteModal />
    </div>
  )
}

export default ServiceQuotePage