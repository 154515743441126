import { Delete, Edit, Password } from '@mui/icons-material';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredKundenData, setKundenChangePasswordModal } from '../../services/clients/KundenSlice';
import { getAllKunden } from '../../services/clients/KundenApi';

const randomData = [
    { numren: 1, name: 'John Doe', email: 'john@example.com', number: '1234567890', date: '2022-01-01' },
    { numren: 2, name: 'Jane Smith', email: 'jane@example.com', number: '9876543210', date: '2022-01-02' },
    // Add more random data objects here...
];

const KundenTable = () => {
    const dispatch = useDispatch();
    const data = useSelector(getFilteredKundenData)

    useEffect(() => {
        dispatch(getAllKunden());
    }, [dispatch]);
  return (
    <Box>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Numren</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Customer Name</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Customer Email</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Customer Number</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Customer Address</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Customer House</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Customer City</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Registered Date</TableCell>
                        <TableCell sx={{ p: '10px 0px 10px 5px'}}>Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                     {data?.map((data, index) => (
                        <TableRow onDoubleClick={(e) => alert(data.date)} key={data.numren}>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{index + 1}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{`${data.firstName} ${data.lastName}`}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{data.email}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{data.phoneNumber}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{data.streetAddress}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{data.houseNumber}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{data.city}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>{data.createdAt}</TableCell>
                            <TableCell sx={{ p: '10px 0px 10px 5px', userSelect: 'none', cursor: 'pointer'}}>
                                <Tooltip title='Reset User Password'>
                                    <IconButton color='info' onClick={(e) => {
                                        e.stopPropagation();
                                    }} size='small'>
                                        <Password fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Edit User'>
                                    <IconButton color={'warning'} onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(setKundenChangePasswordModal(true))
                                    }} size='small'>
                                        <Edit fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Delete User'>
                                    <IconButton color='error' onClick={(e) => {
                                        e.stopPropagation();
                                    }} size='small'>
                                        <Delete fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
            </Table>
        </TableContainer>
    </Box>
  )
}

export default KundenTable