import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isOnline: navigator.onLine,
}

const InternetConnectionSlice = createSlice({
    name: 'InternetConnectionSlice',
    initialState,
    reducers: {
        handleOnlineState(state, action) {
            state.isOnline = action.payload
        },
    }
})
export const {
    handleOnlineState,
} = InternetConnectionSlice.actions;
export const getIsOnline = (state) => state.InternetConnectionSlice.isOnline;
export default InternetConnectionSlice.reducer;