import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../utils/consts';

const url = `${BASE_URL}/api/v1/servicequoterequest`; // Replace 'xx' with your actual API URL

// Create a new ServiceQuote
export const createServiceQuote = createAsyncThunk(
    'serviceQuote/create',
    async (serviceQuoteData, { rejectWithValue }) => {
        try {
            const response = await axios.post(url, serviceQuoteData);

            if (response.status !== 200) {
                throw new Error('Failed to create ServiceQuote');
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Retrieve a ServiceQuote by ID
export const getServiceQuoteById = createAsyncThunk(
    'serviceQuote/getById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${url}/${id}`);

            if (response.status !== 200) {
                throw new Error('Failed to retrieve ServiceQuote');
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getAllServiceQuotes = createAsyncThunk(
    'serviceQuote/getAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${url}/get/all`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Update a ServiceQuote
export const updateServiceQuote = createAsyncThunk(
    'serviceQuote/update',
    async (serviceQuoteData, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${url}/${serviceQuoteData.id}`, serviceQuoteData);

            if (response.status !== 200) {
                throw new Error('Failed to update ServiceQuote');
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Delete a ServiceQuote
export const deleteServiceQuote = createAsyncThunk(
    'serviceQuote/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${url}/${id}`);

            if (response.status !== 200) {
                throw new Error('Failed to delete ServiceQuote');
            }

            return id;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
